*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded',sans-serif;
}

.wisteria{
background-color:#C6AAF3;
color:#C6AAF3;

}

.rich-black{
  background-color:#100E11;
  color: #100E11;
}

.cultured-white{
  background-color:#F5F4F6;
  color:#F5F4F6;
}

input::-webkit-input-placeholder{
  color:#100E11; 
}

input::placeholder{
  color:#100E11; 
}

textarea::-webkit-input-placeholder{
  color:#100E11;
}

textarea::placeholder{
  color:#100E11;
}
